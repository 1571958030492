<template>
  <section
    v-if="isPersonalized && flavorRecos && flavorRecos.regions"
    class="home-standard"
  >
    <div class="row">
      <div class="columns text-center">
        <div class="small-caps-text">Wines For You</div>
        <h2>
          Based on Your Wine
          {{ flavorRecos.type === 'purchase' ? 'Purchases' : 'Ratings' }}
        </h2>
        <div class="carousel-container site-width">
          <div id="flavor-recos-tabs" class="tabs">
            <div
              v-for="({ name, slug }, index) in flavorRecos.regions"
              :id="slug"
              :key="index"
              v-scroll-to="{
                el: `#${slug}`,
                container: '#flavor-recos-tabs',
                offset: -50,
                x: true,
                y: false,
              }"
              class="tab"
              :class="{ active: slug === activeFlavorReco }"
              :data-collection="slug"
              @click="activeFlavorReco = slug"
            >
              {{ name }}
            </div>
          </div>
          <div class="wine-profiles">
            <div
              v-for="{ name, score, index } in flavorRecoRegion(
                activeFlavorReco
              ).profile"
              :key="index"
            >
              <div>
                <img
                  v-if="score >= 1"
                  v-lazy-load data-src="~/assets/images/dot-full.png"
                  alt=""
                />
                <img v-else v-lazy-load data-src="~/assets/images/dot-empty.png" alt="" />
                <img
                  v-if="score >= 2"
                  v-lazy-load data-src="~/assets/images/dot-full.png"
                  alt=""
                />
                <img v-else v-lazy-load data-src="~/assets/images/dot-empty.png" alt="" />
                <img
                  v-if="score >= 3"
                  v-lazy-load data-src="~/assets/images/dot-full.png"
                  alt=""
                />
                <img v-else v-lazy-load data-src="~/assets/images/dot-empty.png" alt="" />
              </div>

              {{ name }}
            </div>
          </div>
          <div
            :key="`flavorreco-carousel-${activeFlavorReco}${restaurantFetchCount}`"
          >
            <ProductGridCarousel
              :carousel-id="'flavorReco-' + activeFlavorReco"
              :items="flavorRecoRegion(activeFlavorReco).wines"
              :title="null"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section v-else class="home-standard">
    <div class="row">
      <div class="columns text-center">
        <h2>Shop Collections</h2>
        <div class="carousel-container site-width">
          <div id="collections-tabs" class="tabs">
            <div
              v-for="slug in shopTheCollectionSlugs"
              :id="slug"
              :key="slug"
              v-scroll-to="{
                el: `#${slug}`,
                container: '#collections-tabs',
                offset: -50,
                x: true,
                y: false,
              }"
              class="tab"
              :class="{ active: slug === activeShopTheCollectionSlug }"
              :data-collection="slug"
              @click="activeShopTheCollectionSlug = slug"
            >
              {{ collectionName(slug) }}
            </div>
          </div>
          <div
            v-if="collectionProducts(activeShopTheCollectionSlug).length > 0"
            :key="activeShopTheCollectionSlug"
          >
            <ProductGridCarousel
              :carousel-id="activeShopTheCollectionSlug"
              :items="collectionProducts(activeShopTheCollectionSlug)"
              :title="null"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { slugify } from '~/../common/utils/slugify'
import { scrollToProductInCarousel } from '~/utils/product'
export default {
  name: 'ShopCollectionsSection',
  required: true,
  components: {
    ProductGridCarousel: () =>
      import('~/components/Product/ProductGridCarousel'),
  },
  props: {
    isPersonalized: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      flavorRecos: null,
      activeFlavorReco: null,
      personalizedSearches: null,
      personalizedProducts: null,
      activeRestaurant: 0,
      shopTheCollectionSlugs: [],
      shopTheCollectionMap: {},
      activeShopTheCollectionSlug: 'popular',
    }
  },
  computed: {
    // Root-level getters
    ...mapGetters(['productIDInView', 'carouselInView', 'qes']),
    ...mapGetters('restaurant', ['restaurantFetchCount']),
  },
  async created() {
    await this.fetchShopTheCollectionData()
    if (this.flavorRecos) {
      if (this.flavorRecos.regions && this.flavorRecos.regions.length >= 1) {
        this.flavorRecos = {
          ...this.flavorRecos,
          regions: this.flavorRecos.regions.map((r) => {
            const name = Object.keys(r)[0]
            return {
              ...r[name],
              name,
              slug: slugify(name),
              profile: Object.keys(r[name].profile).map((profileName) => ({
                name: profileName,
                score: r[name].profile[profileName],
              })),
            }
          }),
        }
        this.activeFlavorReco = this.flavorRecos.regions[0].slug
      }
    }
    if (this.personalizedSearches) {
      const napaSearch = {
        slug: 'napa-valley-cabernet-sauvignon',
        display_name: 'Napa Cabernet',
      }
      // concat data to personalizedSearches according to napaSearch slug
      if (!this.personalizedSearches.find((s) => s.slug === napaSearch.slug)) {
        this.personalizedSearches = this.personalizedSearches.concat([
          napaSearch,
        ])
      }
      const champSearch = {
        slug: 'champagne-sparkling',
        display_name: 'Champagne',
      }
      // concat data to personalizedSearches according to champSearch slug
      if (!this.personalizedSearches.find((s) => s.slug === champSearch.slug)) {
        this.personalizedSearches = this.personalizedSearches.concat([
          champSearch,
        ])
      }
      this.personalizedSearches = this.personalizedSearches.slice(0, 3)
    }
    // default array values
    const defaultShopTheCollectionSlugs = [
      'popular',
      'limited',
      'wine-team-favorites',
      'final-few',
    ]
    const loggedInShopTheCollectionSlugs = [
      'personalized',
      'limited',
      'wine-team-favorites',
      'final-few',
    ]
    if (this.isPersonalized) {
      this.shopTheCollectionMap.personalized = {
        name: 'Wines For You',
        slug: 'personalized',
        products: this.personalizedProducts,
      }
      this.shopTheCollectionSlugs = loggedInShopTheCollectionSlugs
      this.activeShopTheCollectionSlug = 'personalized'
    } else {
      this.shopTheCollectionSlugs = defaultShopTheCollectionSlugs
      this.activeShopTheCollectionSlug = 'popular'
    }
    if (
      this.productIDInView !== undefined &&
      this.carouselInView !== undefined
    ) {
      if (this.carouselInView.startsWith(this.carouselRestaurantPrefix)) {
        const regex = /(\d+)$/
        const match = this.carouselInView.match(regex)
        if (match) {
          this.activeRestaurant = parseInt(match[1])
        } else {
          this.activeShopTheCollectionSlug = this.carouselInView
          scrollToProductInCarousel(this.$store, this.$nextTick)
        }
      }
    }
  },
  methods: {
    async fetchShopTheCollectionData() {
      try {
        const response = await this.$axios.get(
          '/api/homepage/fetch-shop-the-collection-data'
        )
        this.flavorRecos = response.data.flavor_profile_recommendations
        this.personalizedSearches = response.data.top_categories
        this.personalizedProducts = response.data.personalized_products
        this.shopTheCollectionMap = response.data.shop_the_collection.reduce(
          (obj, coll) => Object.assign(obj, { [coll.slug]: coll }),
          {}
        )
      } catch (error) {
        console.error('Error fetching shop the collection data:', error)
      }
    },
    flavorRecoRegion(slug) {
      return (
        (this.flavorRecos.regions || []).find((fr) => fr.slug === slug) || {}
      )
    },
    collectionProducts(slug) {
      return this.shopTheCollectionMap[slug]
        ? this.shopTheCollectionMap[slug].products || []
        : []
    },
    collectionName(slug) {
      return this.shopTheCollectionMap[slug]
        ? this.shopTheCollectionMap[slug].name || 'Collection'
        : 'Collection'
    },
  },
}
</script>
