var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isPersonalized && _vm.flavorRecos && _vm.flavorRecos.regions)?_c('section',{staticClass:"home-standard"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"columns text-center"},[_c('div',{staticClass:"small-caps-text"},[_vm._v("Wines For You")]),_vm._v(" "),_c('h2',[_vm._v("\n        Based on Your Wine\n        "+_vm._s(_vm.flavorRecos.type === 'purchase' ? 'Purchases' : 'Ratings')+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"carousel-container site-width"},[_c('div',{staticClass:"tabs",attrs:{"id":"flavor-recos-tabs"}},_vm._l((_vm.flavorRecos.regions),function(ref,index){
var name = ref.name;
var slug = ref.slug;
return _c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: ("#" + slug),
              container: '#flavor-recos-tabs',
              offset: -50,
              x: true,
              y: false,
            }),expression:"{\n              el: `#${slug}`,\n              container: '#flavor-recos-tabs',\n              offset: -50,\n              x: true,\n              y: false,\n            }"}],key:index,staticClass:"tab",class:{ active: slug === _vm.activeFlavorReco },attrs:{"id":slug,"data-collection":slug},on:{"click":function($event){_vm.activeFlavorReco = slug}}},[_vm._v("\n            "+_vm._s(name)+"\n          ")])}),0),_vm._v(" "),_c('div',{staticClass:"wine-profiles"},_vm._l((_vm.flavorRecoRegion(
              _vm.activeFlavorReco
            ).profile),function(ref){
            var name = ref.name;
            var score = ref.score;
            var index = ref.index;
return _c('div',{key:index},[_c('div',[(score >= 1)?_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":require("assets/images/dot-full.png"),"alt":""}}):_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":require("assets/images/dot-empty.png"),"alt":""}}),_vm._v(" "),(score >= 2)?_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":require("assets/images/dot-full.png"),"alt":""}}):_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":require("assets/images/dot-empty.png"),"alt":""}}),_vm._v(" "),(score >= 3)?_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":require("assets/images/dot-full.png"),"alt":""}}):_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":require("assets/images/dot-empty.png"),"alt":""}})]),_vm._v("\n\n            "+_vm._s(name)+"\n          ")])}),0),_vm._v(" "),_c('div',{key:("flavorreco-carousel-" + _vm.activeFlavorReco + _vm.restaurantFetchCount)},[_c('ProductGridCarousel',{attrs:{"carousel-id":'flavorReco-' + _vm.activeFlavorReco,"items":_vm.flavorRecoRegion(_vm.activeFlavorReco).wines,"title":null}})],1)])])])]):_c('section',{staticClass:"home-standard"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"columns text-center"},[_c('h2',[_vm._v("Shop Collections")]),_vm._v(" "),_c('div',{staticClass:"carousel-container site-width"},[_c('div',{staticClass:"tabs",attrs:{"id":"collections-tabs"}},_vm._l((_vm.shopTheCollectionSlugs),function(slug){return _c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: ("#" + slug),
              container: '#collections-tabs',
              offset: -50,
              x: true,
              y: false,
            }),expression:"{\n              el: `#${slug}`,\n              container: '#collections-tabs',\n              offset: -50,\n              x: true,\n              y: false,\n            }"}],key:slug,staticClass:"tab",class:{ active: slug === _vm.activeShopTheCollectionSlug },attrs:{"id":slug,"data-collection":slug},on:{"click":function($event){_vm.activeShopTheCollectionSlug = slug}}},[_vm._v("\n            "+_vm._s(_vm.collectionName(slug))+"\n          ")])}),0),_vm._v(" "),(_vm.collectionProducts(_vm.activeShopTheCollectionSlug).length > 0)?_c('div',{key:_vm.activeShopTheCollectionSlug},[_c('ProductGridCarousel',{attrs:{"carousel-id":_vm.activeShopTheCollectionSlug,"items":_vm.collectionProducts(_vm.activeShopTheCollectionSlug),"title":null}})],1):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }